.all-colleges-page {
    /* padding: 10px; */
    padding: 2rem;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  .cards-container {
    width: 100%;
    /* display: grid;
    grid-gap: 40px;
    padding-bottom: 1rem;
    padding-top: 1rem; */
  }
  
  .no-data-message {
    color: white;
  }

  .gobackbtn{
    padding: 12px ;
    font-size: 1rem;
    text-transform: uppercase;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    margin: 0 auto; /* This centers the button horizontally */
    display: inline-block; /* To use margin auto for centering */
    margin-top: 2%;
  }
  
  /* @media (min-width: 600px) {
    .cards-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .cards-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
   */