.filters {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    justify-content: center;
  }
  
  .filters select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 150px;
  }
  