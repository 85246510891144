@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.logo_out{
  font-family: "Playfair Display", serif;
  font-optical-sizing:auto;
  font-weight: 500;
  font-style:normal;
  font-size: 33px;
  color: white;
}

.navbar{
  background-color: #05465c;
  border-bottom: 1px solid white;
}


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
  width: 100%;
  height: 80px;
  z-index: 10;
}

.navbar-bg {
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.5s;
}

.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu li a {
  font-size: 1rem;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
}

.hamburger {
  display: none;
}

@media screen and (max-width:1040px) {
  .nav-menu {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      background: rgba(0, 0, 0, 0.0);
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -3;
      transition: 0.3s;
  }

  .nav-menu.active {
      left: 0;
      background-color: rgba(0, 0, 0, 0.85);
  }

  .nav-menu li {
      padding: 1rem 0;
  }

  .nav-menu li a {
      font-size: 1rem;
      font-weight: 400;
  }

  .hamburger {
      display: initial;
  }
}