@import url("https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap");
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 1000

.afacad-flux-<uniquifier> {
  font-family: "Afacad Flux", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
} */

.footer {
  width: 100%;
  background-color: #082c38;
  padding: 1%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  justify-content: space-evenly;
  font-family: "Afacad Flux", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  margin: 0%;
}

.footer a{
  color: white;
  text-decoration: none;
}

.listscontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.list1 {
  padding: 0px;
}
.list2 {
  padding: 0px;
}
.list3 {
  padding: 0px;
}
.list4 div {
  margin: 10px;
}

.social-media {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style-type: none;
}
.listscontainer li{
  margin: 10px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.listscontainer li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 2px;
  background-color: white;
  transition: width 0.25s ease-out;
}

.listscontainer li:hover::before {
  width: 100%;
}

@media screen and (max-width: 680px) {
  .footer {
    width: 100%;
    background-color: #082c38;
    padding: 4%;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: 200;
  }
  .listscontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 8%;
    margin-right: 3%;
  }
  .listscontainer li {
    margin: 10px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
  }
  
  .listscontainer li:hover::before {
    width: 0%;
  }
}
