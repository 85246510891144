.pageNotFound {
  text-align: center;
  padding: 40px 20px;
}

.homeButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin: 2%;
}

.homeButton:hover {
  background-color: #0056b3;
}
