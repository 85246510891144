.detail-page {
  padding: 20px;
  padding-top: 110px;
  text-align: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  align-items: center;
}

.back-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 3%;
}

.back-button:hover {
  background-color: #0056b3;
}

.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
