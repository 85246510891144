.categories-page {
    padding: 20px;
    padding-top: 40px;
    text-align: center;
  }
  
  .categories-page h1 {
    margin-bottom: 20px;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .category-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .category-card h2 {
    margin: 0 0 10px;
  }
  
  .category-card p {
    margin: 0 0 15px;
    color: #666;
  }
  
  .category-card .btn {
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .category-card .btn:hover {
    background-color: #0056b3;
  }
  
  .category-card:hover {
    transform: translateY(-5px);
  }
  