.category-page{
    padding : 0px;
  }
  
  
  .project-container {
    width: 100%;
    /* display: grid;
    grid-gap: 45px; */
    padding: 2rem;
  }
  
  @media (min-width: 600px) {
    .project-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 900px) {
    .project-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  