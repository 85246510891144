.explorebtn{
  padding: 3% 6%;
  font-size: 1rem;
  text-transform: uppercase;
  background: #061f2b;
  color: #222;
  font-weight: 600;
  cursor: pointer;
  border-radius: 7px;
  margin: 0 auto; /* This centers the button horizontally */
  text-align: center; /* Ensures text inside button is centered */
  width: auto; /* Makes sure button width fits content */
  display: block; /* To use margin auto for centering */
}

.explorebtn-light {
  background: transparent;
  color: #fff;
}

.explorebtn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}
