.category-page {
    padding: 0px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .category-page h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .category-page p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: white;
  }
  
  .cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  
  .card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1rem;
    margin-bottom: 15px;
  }
  
  .card .btn {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .card .btn:hover {
    background-color: #0056b3;
  }
  